define(['jquery'], function($) {
  
  var Tabs = function() {

    var self = this;

    /**
     * Initiation function
     * @param config
     */
    function init(config) {
      // Check values and set defaults if necessary
      config.$globalTabs ? true : config.$globalTabs = $('.tabs'),
      config.tabHeading ? true : config.tabHeading = '',
      config.tabBody ? true : config.tabBody = '',
      config.speed ? true : config.speed = 0;
      // Initiate click events
      tabStart(config);
    }

    /**
     * Dynamically set first tab and initiate click events
     * @param config
     */
    function tabStart(config) {
      config.$globalTabs.each(function() {
        $(this).find(config.tabBody).hide().first().show();
        $(this).find(config.tabHeading).first().addClass('active');
      });
      tabsClick(config);
    }

    /**
     * Set clicked tab to display and set rest to hide
     * @param config
     */
    function tabsClick(config) {
      $(config.tabHeading).on('click', function() {
        if (!$(this).hasClass('active')) {
          $(this).closest('.tabs').find(config.tabHeading).removeClass('active');
          $(this).closest('.tabs').find(config.tabBody).hide();
          $(this).addClass('active').parent().find(config.tabBody).stop().fadeIn(config.speed);
        }
      });
    }

    self.init = init;
    self.tabStart = tabStart;
    self.tabsClick = tabsClick;

  };

  var tabs = new Tabs();
  return tabs;
});
